<template>
  <div class="ProjectNotesModal">
    <span class="Title">{{ currentScreeningData.getCommentsOnProjectTitle() }}</span>

    <div class="VueTrixWrapper">
      <VueTrix
        v-model="editorContent"
        :placeholder="mixWB('WRITE_HERE')"
        autofocus />
    </div>

    <div class="ButtonsWrap">
      <Button
        :text="mixWB('SAVE')"
        :fullWidth="false"
        :isLoading="isLoading"
        @button-click="onSaveClick" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import VueTrix from 'vue-trix'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'

export default {
  name: 'ProjectNotesModal',
  data() {
    return {
      editorContent: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
    ]),
  },
  methods: {
    onSaveClick() {
      // Save to DB
      this.$store.dispatch('setNewScreeningValues', [
        { propName: 'reportNotes', value: this.editorContent },
      ])

      this.isLoading = true
      setTimeout(() => {
        // Update report
        EventBus.$emit('project-notes-updated')

        // Close overlay
        this.$emit('close-dialog')
      }, 500)
    },
  },
  components: {
    VueTrix,
    Button,
  },
  created() {
    this.editorContent = this.currentScreeningData.reportNotes || ''
  },
}
</script>

<style lang="stylus" scoped>
  .ProjectNotesModal
    display block

  .Title
    modalTitle()

  .VueTrixWrapper
    padding-top 30px
    margin-bottom 20px
    >>> .trix-button-group--text-tools
      .trix-button--icon-strike
        display none
    >>> .trix-button-group--block-tools
      .trix-button--icon-heading-1,
      .trix-button--icon-quote,
      .trix-button--icon-code
        display none
    >>> .trix-button-group--file-tools
      display none
    >>> .trix-content
      min-height 300px
      max-height 1000px
      overflow auto
      a
        text-decoration underline

  .ButtonsWrap
    display flex
    justify-content flex-end
    >>> .ButtonWrap
      .Button
        min-width 100px
</style>
